import { Component, OnInit } from '@angular/core';
import { Project } from '../../../models/project';
import { Page } from '../../../models/paging/page';
import { Picklist } from '../../../models/picklist';
import { ProjectService } from '../../../services/project.service';
import { PicklistService } from '../../../services/picklist.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { DivisionService } from '../../../services/division.service';
import { Division } from '../../../models/division';
import { AuthService } from '../../../services/auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-active-picklist',
  templateUrl: './active-picklist.component.html',
  styleUrls: ['./active-picklist.component.css']
})
export class ActivePicklistComponent implements OnInit {

  filter: any = {
    projectId: undefined,
    divisionId: undefined,
    status: 0
  };

  projects: Project[];
  divisions: Division[];

  page = new Page();
  rows = Array<Picklist>();

  user = null;

  constructor(private authService: AuthService, private projectService: ProjectService, private divisionService: DivisionService, private reportableService: ReportableService, private picklistService: PicklistService, public router: Router, private snotifyService: SnotifyService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.projectService.getAll().then((data) => {
      this.projects = data;
    });

    this.divisionService.getAll().then((data) => {
      this.divisions = data;
    });

    this.authService.currentUser.subscribe((user) => {
      this.user = user;
    })
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  downloadReport(picklistId, type) {

    /*TYPE:
     1 = MO
     2 = MOM
     3 = TE
     4 = PUWER
     */

    if (type == 1) {
      this.reportableService.getPicklistManifestOutbound(picklistId).then((data) => {
        importedSaveAs(data, 'Manifest Outbound.pdf');
      });
    }

    else if (type == 2) {
      this.reportableService.getPicklistManifestOutboundMissing(picklistId).then((data) => {
        importedSaveAs(data, 'Manifest Outbound Missing.pdf');
      });
    }

    else if (type == 3) {
      this.reportableService.getPicklistTECerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items TE Certs.pdf');
      });
    }
  
    else if (type == 4) {
      this.reportableService.getPicklistPUWERCerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items PUWER Certs.pdf');
      });
    }

    else if (type == 5) {
      this.reportableService.getPicklistPATCerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items PAT Certs.pdf');
      });
    }

    else if (type == 6) {
      this.reportableService.getPicklistCertsCombined(picklistId).then((data) => {
        importedSaveAs(data[0], 'Plant Certs LOLER.pdf');
        importedSaveAs(data[1], 'Plant Certs PUWER.pdf');
      });
    }

  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.picklistService.getOutgoingPicklists(this.page, this.filter.projectId, this.filter.divisionId, this.filter.status).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

  select(picklist: Picklist) {
    console.log(picklist);
  }


  async reinstate(picklist: Picklist) {
    var response = await this.picklistService.reinstatePicklist(picklist)
    if (response) {
      this.snotifyService.success("Successfully reinstated picklist");
      this.loadTable(this.page);
    } else {
      this.snotifyService.error("Something went wrong. Please try again.");
    }
  }

}
