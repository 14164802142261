import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TemplateService } from '../../../../../../services/template.service';
import { Template } from '../../../../../../models/template';
import { CategoryService } from '../../../../../../services/category.service';
import { SubCategoryService } from '../../../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../../../services/item-types.service';
import { Category } from '../../../../../../models/category';
import { ItemType } from '../../../../../../models/item-type';
import { PickListAssetViewModel } from '../../../../../../models/picklist-asset-view-model';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../../../../../../services/auth.service';

@Component({
  selector: 'app-picklist-template',
  templateUrl: './picklist-template.component.html',
  styleUrls: ['./picklist-template.component.css']
})
export class PicklistTemplateComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() searchFilterName: string;
  @Input() searchFilterDesc: string;

  categories: Category[];
  itemTypes: ItemType[];

  templates: Template[];
  name: "";
  selectedTemplateId: string = '';
  userRoleId: number;

  picklistAssets: PickListAssetViewModel[] = [];

  constructor(private templateService: TemplateService, private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService,
    private confirmationService: ConfirmationService, private authService: AuthService) {
    this.userRoleId = authService.currentUser.value.userType;
  }

  ngOnInit() {
    this.loadCategories().then(() => {
      this.templateService.getAll().then((templates) => {

        var select = new Template();
        select.id = '';
        select.name = 'New';

        templates.unshift(select);
        this.templates = templates;
      });
    });
  }


  loadCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.categoryService.getAll().then((categories) => {
        this.subCategoryService.getAll().then((subCategories) => {
          this.itemTypesService.getAll(true).then((itemTypes) => {
            for (let category of categories) {
              category.subCategories = subCategories.filter(a => a.categoryId == category.id);
              for (let subCategory of subCategories) {
                subCategory.itemTypes = itemTypes.filter(b => b.subCategoryId == subCategory.id);
              }
            }

            var allCat = new Category();
            allCat.init("-1", "ALL ITEMS", false);
            allCat.subCategories = subCategories;
            categories.unshift(allCat);
            this.categories = categories;


            resolve();
          });
        });

      });
    });
  }


  templateChanged() {
    this.loadCategories().then(() => {
      var selectedTemplate = this.templates.find(a => a.id == this.selectedTemplateId);
      //console.log(selectedTemplate);
      for (let category of this.categories) {
        for (let subCategory of category.subCategories) {
          for (let itemType of subCategory.itemTypes) {
            var currentAssetItemType = selectedTemplate.templateAssets.find(a => a.itemTypeId == itemType.id);
            if (currentAssetItemType != null) {
              itemType.quantity = currentAssetItemType.quantity;
              itemType.notes = currentAssetItemType.notes;
            }

            var currentNonAssetItemType = selectedTemplate.templateNonNumberedAssets.find(a => a.itemTypeId == itemType.id);
            if (currentNonAssetItemType != null) {
              itemType.quantity = currentNonAssetItemType.quantity;
              itemType.notes = currentNonAssetItemType.notes;
            }
          }
        }
      }
    });
  }

  selectItems(items: ItemType[]) {
    this.itemTypes = items;
  }

  save(saveTemplate = false) {
    this.picklistAssets = [];

    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          if (itemType.quantity > 0) {
            this.generateAsset(itemType);
            for (let linkedItemType of itemType.linkedItemTypes) {
              if (linkedItemType.itemType.quantity > 0)
                this.generateAsset(linkedItemType.itemType);
            }
          }
        }
      }
    }

    // We have to save this as a new template list
    if (this.selectedTemplateId == "") {
      var template = new Template();
      template.name = this.name;
      template.allPicklistAssets = this.picklistAssets;
      template.isEdit = false;

      this.templateService.save(template).then((res) => {
        //console.log(res);
        this.completed.emit(this.picklistAssets);
      });
    } else if (saveTemplate) { // This template is being edited
      var selectedTemplate = this.templates.find(a => a.id == this.selectedTemplateId);
      selectedTemplate.allPicklistAssets = this.picklistAssets;
      selectedTemplate.isEdit = true;

      this.templateService.save(selectedTemplate).then((res) => {
        this.completed.emit(this.picklistAssets);
      });
    } else {
      this.completed.emit(this.picklistAssets);
    }


  }

  generateAsset(item: ItemType) {
    if (item.numbered) {
      this.generatePickListAsset(item);
    } else {
      this.generateNonNumberedAsset(item);
    }
  }

  generatePickListAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = true;
    this.picklistAssets.push(pickListAsset);
  }

  generateNonNumberedAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = false;
    this.picklistAssets.push(pickListAsset);
  }

  removeTemplate() {
    this.confirmationService.confirm({
      message: "Are you sure you want to remove this template?",
      accept: () => {
        var selectedTemplate = this.templates.find(a => a.id == this.selectedTemplateId);

        this.templateService.expire(selectedTemplate).then((res) => {
          this.templateService.getAll().then((templates) => {
            this.selectedTemplateId = "";

            var select = new Template();
            select.id = '';
            select.name = 'New';

            templates.unshift(select);
            this.templates = templates;
          });
        });
      }
    });
  }
}
